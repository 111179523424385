


























































































































import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Message from '@/components/ui/Message.vue'
import TipPanel from '@/components/TipPanel.vue'
import UserForm from '@/components/my/UserForm.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import VInput from '@/components/form/VInput.vue'
import VueRecaptcha from 'vue-recaptcha'
import CustomLink from '@/components/g/CustomLink.vue'

import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  toRef,
  watch,
} from '@vue/composition-api'
import { AxiosResponse } from 'axios'
import { fetchLive } from '@/inc/utils'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import auth from '@/composables/auth'
import userAccountRequest from '@/composables/userAccountRequest'

interface User {
  Firstname: string
  Lastname: string
  Email: string
  Phone: string
  PhoneFixe?: string
  Adresse: {
    Cdpostal: string
    Localite: string
    Rue: string
    NumRue: string
    CodePays: string
  }
}

export default defineComponent({
  name: 'my-signup',
  components: {
    Message,
    FeedbackMessage,
    TipPanel,
    UserForm,
    VCheckbox,
    VInput,
    VueRecaptcha,
    CustomLink,
  },
  props: {
    user: {
      required: false,
      type: Object as () => Record<string, string>,
    },
  },

  setup(props, ctx) {
    const { chrome } = useState(['chrome'])
    const myState = {
      ...useState('my', ['i18n']),
    }
    const { $logger } = ctx.root
    const form = ref<HTMLFormElement>()
    const isLoading = ref(false)
    const isSuccessfull = ref(false)
    const isModified = ref(false)
    const gdpr = ref(false)
    const isReady = ref(false)
    const errorMessage = ref('')
    const recaptcha = ref('')
    const recaptchaKey = ref(chrome.value.data?.recaptcha)
    const inputsValues = reactive<User>({
      Firstname: props.user?.bpPrenom || '',
      Lastname: props.user?.bpNom || '',
      Email: props.user?.bpEmail || '',
      Phone: props.user?.bpTel || '',
      PhoneFixe: props.user?.bpTel || '',
      Adresse: {
        Cdpostal: props.user?.bpCdPostal || '',
        Localite: props.user?.bpLocalite || '',
        Rue: props.user?.bpRue || '',
        NumRue: props.user?.bpNumRue || '',
        CodePays: 'BE',
      },
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const i18n = ref<Record<string, any>>()
    const content = ref<Record<string, any>>()
    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const phoneError = ref(false)
    ctx.root.$on('error:phone', (value: boolean) => handleErrorPhone(value))
    const handleErrorPhone = value => {
      phoneError.value = value
    }

    const landlineError = ref(false)
    ctx.root.$on('error:landline', (value: boolean) =>
      handleErrorLandline(value)
    )
    const handleErrorLandline = value => {
      landlineError.value = value
    }

    const checkAuth = async () => {
      if (auth.isLogged.value === true) {
        await auth.signOut(false)
      }
    }

    const showGDPRPanel = () => {
      showPanel({
        component: { template: TipPanel },
        content: content.value?.inlineTips.gdpr,
      })
    }

    const onInput = () => {
      isModified.value = true
    }
    /* eslint-disable */
    const disabledCondition = computed(() => {
      return !(
        '' !== inputsValues.Firstname &&
        '' !== inputsValues.Lastname &&
        '' !== inputsValues.Phone &&
        '' !== inputsValues.Email &&
        gdpr.value === true &&
        inputsValues.Adresse.Cdpostal &&
        '' !== inputsValues.Adresse.Cdpostal &&
        inputsValues.Adresse.Localite &&
        '' !== inputsValues.Adresse.Localite &&
        inputsValues.Adresse.Rue &&
        '' !== inputsValues.Adresse.Rue &&
        inputsValues.Adresse.NumRue &&
        '' !== inputsValues.Adresse.NumRue &&
        inputsValues.Adresse.CodePays &&
        '' !== inputsValues.Adresse.CodePays &&
        !phoneError.value &&
        ((inputsValues.PhoneFixe && !landlineError.value) ||
          !inputsValues.PhoneFixe) &&
        recaptcha.value !== '' &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          inputsValues.Email
        )
      )
    })
    const handleChange = async () => {
      inputsValues.Adresse.CodePays =
        inputsValues.Adresse.CodePays === ''
          ? 'BE'
          : inputsValues.Adresse.CodePays
      inputsValues.PhoneFixe =
        inputsValues.PhoneFixe === '+32' ? '' : inputsValues.PhoneFixe

      const { res, error } = await userAccountRequest(
        'users',
        'patch',
        inputsValues
      )

      return { user: res?.data, error: error?.data }
    }

    const signUp = async () => {
      form.value!.reportValidity()
      $logger.debug('signUp')
      errorMessage.value = ''

      if (!form.value!.checkValidity()) {
        return
      }

      isLoading.value = true

      if (isModified.value) {
        const { error } = await handleChange()

        if (error) {
          $logger.error('USER UPDATE', error)
          errorMessage.value = error?.response?.data?.message

          return
        }
      }

      const { currentLang } = useGetters(['currentLang'])
      const host =
        process.env.VUE_APP_ENV === 'development'
          ? 'http://0.0.0.0:4000'
          : process.env.VUE_APP_MYRESA_DOMAIN

      const path = props.user
        ? `/${currentLang.value === 'de' ? 'de' : 'fr'}/ppp/password/`
        : `/${currentLang.value === 'de' ? 'de' : 'fr'}/signup/password/`

      const { error } = await userAccountRequest(
        'users/activate/sendMail',
        'post',
        {
          // eslint-disable-next-line quote-props
          Email: inputsValues.Email,
          'g-recaptcha-response': recaptcha.value,
        },
        {
          params: {
            Callback: host + path,
            Callback2FA:
              host +
              `/${
                currentLang.value === 'de' ? 'de' : 'fr'
              }/signup/validate-sms/`,
          },
        }
      )

      if (error) {
        $logger.error('USER ACTIVATE', error)
        errorMessage.value = error?.response?.data?.message
      } else {
        isSuccessfull.value = true
      }

      isLoading.value = false
    }

    const messageContent = computed(() =>
      i18n.value?.confirmMail?.message?.htmltext.replace(
        /{#??email}/,
        `<b>${inputsValues.Email}</b>`
      )
    )

    const globalI18n = computed(() => chrome.value.data.i18n)

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    onBeforeMount(async () => {
      await checkAuth()

      const { route: createRoute } = ctx.root.$router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      i18n.value = res.data.i18n || {}
      content.value = res.data.content || {}
    })

    watch(toRef(chrome.value, 'data'), newValue => {
      recaptchaKey.value = newValue.recaptcha
    })

    return {
      handleErrorPhone,
      handleErrorLandline,
      chrome,
      errorMessage,
      form,
      gdpr,
      globalI18n,
      i18n,
      inputsValues,
      isLoading,
      isReady,
      isSuccessfull,
      messageContent,
      onInput,
      phoneError,
      recaptcha,
      recaptchaKey,
      resetRecaptcha,
      setRecaptcha,
      showGDPRPanel,
      signUp,
      disabledCondition,
      myI18n: myState.i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
