var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.myI18n.confirmCreation)?_c('div',{staticClass:"my-signup wrapper m-l"},[(!_vm.isSuccessfull && _vm.myI18n.confirmCreation)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxxs col-m-14"},[(_vm.myI18n.confirmCreation.picture)?_c('img',{staticClass:"mb-xs",attrs:{"alt":_vm.myI18n.confirmCreation.picture.alt ||
            _vm.myI18n.confirmCreation.picture.title,"src":_vm.apiDomain + _vm.myI18n.confirmCreation.picture.url}}):_vm._e(),(_vm.myI18n.confirmCreation.title)?_c('h1',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.myI18n.confirmCreation.title)+" ")]):_vm._e(),(_vm.myI18n.confirmCreation.optionHtml.displayContent.length > 0)?_c('div',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.myI18n.confirmCreation.optionHtml.htmltext)}}):_vm._e(),(_vm.myI18n.confirmCreation.subtitle)?_c('h2',{staticClass:"fatline h3 mb-m"},[_vm._v(" "+_vm._s(_vm.myI18n.confirmCreation.subtitle)+" ")]):_vm._e()])]),_c('form',{ref:"form",staticClass:"form row col-xxxs col-m-14",on:{"change":_vm.onInput,"input":_vm.onInput}},[(_vm.myI18n.confirmCreation.infos)?_c('span',{staticClass:"col-xxxs-20 h5 mb-xxs"},[_vm._v(" "+_vm._s(_vm.myI18n.confirmCreation.infos)+" ")]):_vm._e(),_c('user-form',{staticClass:"col-xxxs-20 row",attrs:{"model":_vm.inputsValues,"role":"signup"}}),_c('v-checkbox',{staticClass:"col-xxxs-20 mb-s",attrs:{"id":"gdpr","label":_vm.i18n.personnal.fields.gdpr,"required":true},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}},[_c('g-action',{staticClass:"form__gdpr",attrs:{"content":{
            label: _vm.i18n.personnal.fields.gdprLabel,
            url: '#',
          }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.showGDPRPanel($event)}}})],1),(_vm.recaptchaKey)?_c('vue-recaptcha',{staticClass:"mb-s",attrs:{"language":_vm.chrome.currentLang !== null ? _vm.chrome.currentLang : 'en',"loadRecaptchaScript":true,"sitekey":_vm.recaptchaKey},on:{"expired":_vm.resetRecaptcha,"verify":_vm.setRecaptcha}}):_vm._e(),(_vm.globalI18n)?_c('span',{staticClass:"small col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.globalI18n.requiredFields)+" ")]):_vm._e(),(_vm.errorMessage)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{ type: 'error', htmltext: _vm.errorMessage }}}):_vm._e(),_c('CustomLink',{staticClass:"submit mt-s",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
          label: _vm.i18n.personnal.button.label,
          icon: {
            size: '0 0 24 24',
            name: 'arrows-large-right',
          },
        },"disabled":_vm.disabledCondition,"modifiers":['btn', 'icon', 'arrow'],"type":'button'},on:{"btnClick":_vm.signUp}})],1)]:[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxxs col-m-14"},[(_vm.i18n.confirmMail.title)?_c('h1',{staticClass:"mb-m"},[_vm._v(" "+_vm._s(_vm.i18n.confirmMail.title)+" ")]):_vm._e(),(_vm.i18n.confirmMail.subtitle)?_c('h2',{staticClass:"fatline h3 mb-l"},[_vm._v(" "+_vm._s(_vm.i18n.confirmMail.subtitle)+" ")]):_vm._e(),_c('message',{attrs:{"content":{
            label: _vm.i18n.confirmMail.message.label,
            htmltext: _vm.messageContent,
            modifiers: _vm.i18n.confirmMail.message.modifiers,
          },"severity":"success"}})],1)])]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }